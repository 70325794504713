#nprogress {
	pointer-events: none;

	.bar {
		background: #ff7f41;
		position: fixed;
		z-index: 9999;
		top: 0;
		left: 0;
		width: 100%;
		height: 0.3rem;
	}
}

// loader styles

.loader {
	display: flex;
}

.loader > div {
	width: 8px;
	height: 8px;
	margin: 4px;
	background: #d4c8b9;
	border-radius: 100%;
	animation: scaleUpDown 1.2s ease-in-out infinite;
}

.loader.white > div {
	background: #ffffff;
}

.loader.primary > div {
	background: #1f70f1;
}

.loader.error > div {
	background: #ae2926;
}

.loader .dot1 {
	animation-delay: 0.3s;
}

.loader .dot2 {
	animation-delay: 0.6s;
}

.loader .dot3 {
	animation-delay: 0.9s;
}

@keyframes scaleUpDown {
	0% {
		transform: scale(1);
	}
	30% {
		transform: scale(1.8);
	}
	60% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}
